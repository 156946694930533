import Amplify, { Hub } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.min.css';
import { history } from 'helpers/common';
import { Router } from "react-router-dom";
import "assets/css/app.css";
import "./App.css";
import config from './aws_export';
import Routes from './routes';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { authActionType } from 'store/actionTypes';
Amplify.configure(config)

const App = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          localStorage.setItem('token', data.signInUserSession.idToken.jwtToken)
          localStorage.setItem('refresh_token', data.signInUserSession.refreshToken.token)
          localStorage.setItem('user', JSON.stringify(data.signInUserSession.idToken.payload.email))
          dispatch({
            type: authActionType.LOGIN_SUCCESS,
            payload: true
          })
          dispatch({
            type: authActionType.REMEMBER_ME,
          })
          dispatch({
            type: authActionType.ERROR_LOGIN,
            payload: ''
          })
          break;
        case 'signOut':
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });
  });


  return (
    <Router history={history}>
      <Routes />
    </Router>
  )
}

export default App;

export const authActionType = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  REMEMBER_ME: "REMEMBER_ME",
  LOGOUT: "LOGOUT",
  ERROR_LOGIN: "ERROR_LOGIN",
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
  FIRST_TIME_RESET_PASSWORD_SUCCESS: "FIRST_TIME_RESET_PASSWORD_SUCCESS",
  ERROR_FIRST_TIME_RESET_PASSWORD: "ERROR_FIRST_TIME_RESET_PASSWORD",
  SET_FIRST_TIME_RESET_PASSWORD_DATA: "SET_FIRST_TIME_RESET_PASSWORD_DATA",
};

export const appActionType = {
  SET_LOADING: "SET_LOADING",
  SET_ALERT: "SET_ALERT",
  REMOVE_ALERT: "REMOVE_ALERT",
  RELOAD_MESSAGE: "RELOAD_MESSAGE",
};


export const materialActionType = {
  GET_LIST_MATERIAL: "GET_LIST_MATERIAL",
  GET_MATERIALS_SUCCESS: "GET_MATERIALS_SUCCESS",
};
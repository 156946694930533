import { combineReducers } from 'redux'
import auth from './auth'
import app from './app'
import alert from './alert'
import material from './material'

export default combineReducers({
    auth,
    app,
    alert,
    material
})
import { appActionType } from "store/actionTypes"

export const add = (message, alertType="success") => ({
    type: 'SET_ALERT',
    message,
    alertType
})

export const remove = () => ({
    type: 'REMOVE_ALERT'
})

export const reloadMessage = (message) => ({
    type: appActionType.RELOAD_MESSAGE,
    payload: message
})

export const clearReloadMessage = () => ({
    type: appActionType.RELOAD_MESSAGE,
    payload: ''
})
import { materialActionType } from "../actionTypes";

const initialState = {
  materials: [],
  isAddMaterialSuccess: "",
  isUpdateMaterialSuccess: false,
  errorMaterial: "",
  isDeleteMaterialSuccess: false,
  isOrderMaterialSuccess: false,
  isGetMaterialsSuccess: false,
}

const material = (state = initialState, action) => {
  switch (action.type) {

    case materialActionType.GET_LIST_MATERIAL:
      return {
        ...state,
        materials: action.payload,
      }
    case materialActionType.GET_MATERIALS_SUCCESS:
      return {
        ...state,
        isGetMaterialsSuccess: action.payload,
      }
    default:
      return state
  }
}

export default material;
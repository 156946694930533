/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
export const REACT_APP_API_ENDPOINT = 'https://8vqn633pg0.execute-api.ap-southeast-2.amazonaws.com/prod'

export const REACT_APP_COGNITO_REGION = 'ap-southeast-2'
export const REACT_APP_COGNITO_REDIRECT = 'fieldloggingenv://'
export const REACT_APP_S3_BUCKET = 'wekaapp-prod-application-bucket'
export const REACT_APP_S3_FOLDER = 'public/Disciplines'

// web-portal-prod ENV
export const REACT_APP_COGNITO_USERPOOL_ID = 'ap-southeast-2_l1JynJI1E'
export const REACT_APP_COGNITO_IDENTITYPOOL_ID = 'ap-southeast-2:87168778-25d1-4702-baa7-458200e444c0s'
export const REACT_APP_COGNITO_CLIENT_ID = '5r5sgjleeh1u2l438nlcpptk6v'
export const REACT_APP_COGNITO_DOMAIN = 'wekaappprodadmin.auth.ap-southeast-2.amazoncognito.com'

// web-portal-dev01 ENV 
// export const REACT_APP_COGNITO_USERPOOL_ID = 'ap-southeast-2_cklsLVZop'
// export const REACT_APP_COGNITO_IDENTITYPOOL_ID = 'ap-southeast-2:4eb6c2ec-5016-45df-9944-12a6b11ac179'
// export const REACT_APP_COGNITO_CLIENT_ID = '2esdf73rh3ibnt88v4061h81tf'
// export const REACT_APP_COGNITO_DOMAIN = 'fieldlogging-webportal-dev01.auth.ap-southeast-2.amazoncognito.com'

// DEV ENV 
//export const REACT_APP_COGNITO_USERPOOL_ID = 'ap-southeast-2_SNCm3LrE7'
//export const REACT_APP_COGNITO_IDENTITYPOOL_ID = 'ap-southeast-2:c748e2f2-0513-4666-b9ca-d91f7cb1429b'
//export const REACT_APP_COGNITO_CLIENT_ID = '1t293261hf96a5ig03k1irkfp2'
//export const REACT_APP_COGNITO_DOMAIN = 'fieldlogging-webportal-prod.auth.ap-southeast-2.amazoncognito.com'

// DEV01 ENV 
// export const REACT_APP_COGNITO_USERPOOL_ID = 'ap-southeast-2_Dm63VaHno'
// export const REACT_APP_COGNITO_IDENTITYPOOL_ID = 'ap-southeast-2:4851268d-ef55-41fa-be9e-aa922e473848'
// export const REACT_APP_COGNITO_CLIENT_ID = '47gfv18rs5s3hgdifkanen0l54'
// export const REACT_APP_COGNITO_DOMAIN = 'fieldlogging-dev01.auth.ap-southeast-2.amazoncognito.com'

const awsConfig = {
    Auth: {
        "aws_project_region": REACT_APP_COGNITO_REGION,
        "aws_cognito_region": REACT_APP_COGNITO_REGION,
        "aws_user_pools_id": REACT_APP_COGNITO_USERPOOL_ID,
        "aws_user_pools_web_client_id": REACT_APP_COGNITO_CLIENT_ID
      },
    oauth: {
      domain: REACT_APP_COGNITO_DOMAIN,
      scope: [
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin',
      ],
      clientID: REACT_APP_COGNITO_CLIENT_ID,
      localRedirectSignIn: REACT_APP_COGNITO_REDIRECT,
      localRedirectSignOut: REACT_APP_COGNITO_REDIRECT,
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_POOLS',
};

const config = {
  ...awsConfig.Auth,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
  }
}

export default config;

import { appActionType } from "../actionTypes"


const initialState = {
    show: false,
    message: "",
    type: "success",
    reload_message: "",
};

const alert = (state = initialState, action) => {
    switch (action.type) {
        case appActionType.SET_ALERT:
            return {
                ...state,
                show: true,
                message: action.message,
                type: action.alertType
            }
        case appActionType.REMOVE_ALERT:
            return {
                ...state,
                show: false,
                message: ""
            }
        case appActionType.RELOAD_MESSAGE:
            return {
                ...state,
                reload_message: action.payload,
            }
        default:
            return state;
    }
}

export default alert
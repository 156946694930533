import { REACT_APP_API_ENDPOINT } from 'aws_export'
import axios from 'axios'

axios.defaults.baseURL = REACT_APP_API_ENDPOINT

axios.interceptors.request.use(function (axios_config: any) {
    axios_config.headers.Authorization = "Bearer " + localStorage.getItem('token')
    return axios_config
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
})

export default axios

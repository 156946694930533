import { lazy } from "react"
import { Route, Switch, useHistory } from "react-router-dom"
import { remove } from "store/actions/alert"
import { useAppDispatch } from "hooks"

const Login = lazy(() => import('pages/Auth/Login'))
const Dashboard = lazy(() => import('pages/Dashboard/index'))
const NotFound = lazy(() => import('pages/Error/NotFound'))

type routeItem = {
    path: string,
    name: string,
    title: string,
    key: string,
    exact: boolean,
    component: Function,
    requiredAuth: boolean,
    roles: Array<string>
}

type routes = routeItem & {
    routes?: routeItem[]
}

const ROUTES: routes[] = [
    {
        path: "/",
        name: "homepage",
        title: "Admin Dashboard",
        key: "homepage",
        exact: true,
        component: Dashboard,
        routes: [],
        requiredAuth: true,
        roles: ["admin", "geotechnics"]
    },
    {
        path: "/login",
        name: "login",
        title: "Login",
        key: "login",
        exact: true,
        component: Login,
        routes: [],
        requiredAuth: false,
        roles: ["admin", "geotechnics"]
    }
]

export default ROUTES


export function RenderRoutes({ routes }: { routes: routes[] }) {
    return (
        <Switch>
            {routes.map((route, i) => {
                return <RouteWithSubRoutes {...route} />
            })}
            <Route component={() => <NotFound />} />
        </Switch>
    )
}

function RouteWithSubRoutes(route: routes) {
    const dispatch = useAppDispatch()
    const history = useHistory()
    document.title = route.title

    return (
        <Route
            key={route.key}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={(props) => {
                dispatch(remove())
                localStorage.setItem("currentRoute", route.name)

                if (!localStorage.getItem("token")) {
                    route.requiredAuth && history.push("/login")
                } else {
                    ['/login', '/forgot-password'].includes(route.path) && history.push("/")
                }
                return <route.component {...props} routes={route.routes} routeName={route.name} />
            }
            } />
    )
}